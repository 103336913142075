import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SideDrawer from './SideDrawer';

const Layout = (props: LayoutProps) => {
  const isSideDrawerOpen = useSelector(
    (state: AppState) => state.nav.isSideDrawerOpen
  );
  const whichUserFlow = useSelector(
    (state: AppState) => state.nav.whichUserFlow
  );

  const [contentClass, setContentClass] = useState('content ');

  useEffect(() => {
    let contentClass = 'content ';
    if (isSideDrawerOpen) contentClass += 'side-drawer-open ';
    setContentClass(contentClass + whichUserFlow);
  }, [isSideDrawerOpen, whichUserFlow]);

  return (
    <div className="layout">
      <SideDrawer whichUserFlow={whichUserFlow} />
      <div className={contentClass}>{props.children}</div>
    </div>
  );
};

export default Layout;
