import { Navigate, Outlet, useLocation } from 'react-router';

const ProtectedRoutes = ({ isAuthenticated }: ProtectedRoutesProps) => {
  const location = useLocation();

  // ! this can also render a login modal over the current page redirect to login page or redirect BACK to the current page after login
  return isAuthenticated ? (
    // if authenticated render the child routes
    <Outlet />
  ) : (
    // if not authenticated redirect to login page
    <Navigate to="/login-or-signup" replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
