import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  whichUserFlow: 'home-flow',
  isSideDrawerOpen: false,
} satisfies NavigationStateType as NavigationStateType;

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setCurrentUserflow: (
      state: NavigationStateType,
      action: PayloadAction<any>
    ) => {
      const currentUserFlow = action.payload;
      state.whichUserFlow = currentUserFlow;
      if ('auth-flow' === currentUserFlow) state.isSideDrawerOpen = false;
    },
    setIsSideDrawerOpen: (
      state: NavigationStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isSideDrawerOpen = action.payload;
    },
  },
});

export const { setCurrentUserflow, setIsSideDrawerOpen } =
  navigationSlice.actions;

export default navigationSlice.reducer;
