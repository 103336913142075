import {
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

export const blogListDefaultCases = (
  builder: ActionReducerMapBuilder<BlogListStateType>,
  asyncThunk: AsyncThunk<any, any, any>
) => {
  builder.addCase(asyncThunk.pending, (state: BlogListStateType) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncThunk.fulfilled,
    (state: BlogListStateType, action: PayloadAction<ServerPaginatorType>) => {
      destructurePyPaginator(state, action);
      state.isLoading = false;
    }
  );

  builder.addCase(
    asyncThunk.rejected,
    (state: BlogListStateType, action: any) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.detail;
    }
  );

  return builder;
};

export const destructurePyPaginator = (
  state: BlogListPaginatorType,
  action: PayloadAction<ServerPaginatorType>
) => {
  state.paginator = {
    count: action.payload.count,
    nextPageUrl: action.payload.next,
    prevPageUrl: action.payload.previous,
    pageSize: action.payload.page_size,
    seed: action.payload.seed,
  };
  state.blogs = action.payload.results;
};

export const initBlogListType: () => BlogListPaginatorType = () => {
  return {
    paginator: {
      count: 0,
      nextPageUrl: null,
      prevPageUrl: null,
      pageSize: 0,
    },
    isLoading: false,
    errorMessage: '',
    blogs: [],
  };
};
