import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest } from './requestUtil';

export const getAllSubjectTags = createAsyncThunk('sign-up-user', async () => {
  return sendGetRequest('api/tags/');
});

export const getBookmarkedBlogs = createAsyncThunk(
  'get-bookmarked-blogs',
  async (email: string = '') => {
    return sendGetRequest(`api/blogs/bookmarks/?email=${email}`);
  }
);
