import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import { convertStringArrayToTagOptionsArray } from '../../utils/conversionUtil';
import {
  createUpdateProfile,
  getAllUserInfoByEmail,
  getUserByUsername,
  loginUser,
  signUpUser,
  uploadProfilePicture,
} from '../actions/authActions';
import { saveBlogBookmark } from '../actions/blogPageActions';
import { getBookmarkedBlogs } from '../actions/profileActions';

const initialState = {
  // User information
  userId: 0,
  authToken: '',
  email: '',
  username: '',
  // Profile information
  profilePicture: '',
  bio: '',
  interests: [],
  bookmarkedBlogs: [],
  // User status
  isUserFound: false,
  isLoggedIn: false,
  isUserLoading: false,
  loginErrorMessage: '',
} satisfies UserStateType as UserStateType;

const userSlice = createSlice({
  name: 'userStateSlice',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    setUserEmail: (state: UserStateType, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIsUserLoading: (
      state: UserStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isUserLoading = action.payload;
    },
    removeBookmark: (state: UserStateType, action: PayloadAction<number>) => {
      state.bookmarkedBlogs = state.bookmarkedBlogs.filter(
        (blog: BlogCard) => blog.id !== action.payload
      );
    },
  },
  extraReducers: builder => {
    userLoginCases(builder);
    userSignUpCases(builder);
    getAllUserInfoByEmailCases(builder);
    getUserByUsernameCases(builder);
    uploadProfilePictureCases(builder);
    createUpdateProfileCases(builder);
    getBookmarkedBlogsCases(builder);
    saveBookmarkBlogCases(builder);
  },
});

const userLoginCases = (builder: ActionReducerMapBuilder<UserStateType>) => {
  builder.addCase(loginUser.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    loginUser.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      state.isUserLoading = false;
      state.isLoggedIn = true;
      state.loginErrorMessage = '';
      state.authToken = action.payload?.auth_token;
    }
  );

  builder.addCase(loginUser.rejected, (state: UserStateType, action: any) => {
    state.isUserLoading = false;
    state.isLoggedIn = false;
  });

  return builder;
};

const userSignUpCases = (builder: ActionReducerMapBuilder<UserStateType>) => {
  builder.addCase(signUpUser.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    signUpUser.fulfilled,
    (state: UserStateType, action: PayloadAction<SignupLoginSuccessType>) => {
      state.isUserLoading = false;
      state.isLoggedIn = true;
      state.userId = action.payload.id;
      state.username = action.payload.username;
      state.email = action.payload.email;
    }
  );
  builder.addCase(signUpUser.rejected, (state: UserStateType) => {
    state.isUserLoading = false;
  });
  return builder;
};

const getAllUserInfoByEmailCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(getAllUserInfoByEmail.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    getAllUserInfoByEmail.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      const userRes = action.payload.user;
      state.userId = userRes?.id ? userRes.id : state.userId;
      state.email = userRes?.email ? userRes.email : state.email;
      state.username = userRes?.username ? userRes.username : state.username;

      const profileRes = action.payload.profile;
      state.bio = profileRes?.about_me ? profileRes.about_me : state.bio;
      state.interests = profileRes?.tags
        ? convertStringArrayToTagOptionsArray(profileRes.tags)
        : state.interests;
      state.profilePicture = profileRes?.avatar
        ? profileRes.avatar
        : state.profilePicture;

      state.isUserLoading = false;
    }
  );

  builder.addCase(getAllUserInfoByEmail.rejected, (state: UserStateType) => {
    state.isUserLoading = false;
  });
  return builder;
};

const getUserByUsernameCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(getUserByUsername.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    getUserByUsername.fulfilled,
    (state: UserStateType, action: PayloadAction<getUserSuccessType>) => {
      state.isUserLoading = false;
    }
  );

  builder.addCase(
    getUserByUsername.rejected,
    (state: UserStateType, action: any) => {
      state.isUserLoading = false;
    }
  );
  return builder;
};

const uploadProfilePictureCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder
    .addCase(uploadProfilePicture.pending, (state: UserStateType) => {
      state.isUserLoading = true;
    })
    .addCase(
      uploadProfilePicture.fulfilled,
      (state: UserStateType, action: PayloadAction<any>) => {
        state.isUserLoading = false;
        state.profilePicture = action.payload['avatar-url'];
      }
    )
    .addCase(
      uploadProfilePicture.rejected,
      (state: UserStateType, action: any) => {
        state.isUserLoading = false;
      }
    );

  return builder;
};

const createUpdateProfileCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(createUpdateProfile.pending, (state: any) => {
    state.isUserLoading = true;
  });
  builder.addCase(
    createUpdateProfile.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      state.isUserLoading = false;
      state.profilePicture = action.payload.avatar;
      state.bio = action.payload.about_me;
      state.interests = convertStringArrayToTagOptionsArray(
        action.payload.tags
      );
    }
  );
  builder.addCase(
    createUpdateProfile.rejected,
    (state: UserStateType, action: any) => {
      state.isUserLoading = false;
    }
  );
  return builder;
};

export const getBookmarkedBlogsCases = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(
      getBookmarkedBlogs.fulfilled,
      (state: UserStateType, action: PayloadAction<any>) => {
        state.isUserLoading = false;
        state.bookmarkedBlogs = action.payload['bookmarked_blogs'];
      }
    )
    .addCase(getBookmarkedBlogs.rejected, (state: UserStateType) => {
      state.isUserLoading = false;
    })
    .addCase(getBookmarkedBlogs.pending, (state: UserStateType) => {
      state.isUserLoading = true;
    });
};

const saveBookmarkBlogCases = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(
      saveBlogBookmark.fulfilled,
      (state: UserStateType, action: PayloadAction<any>) => {
        state.bookmarkedBlogs.push(action.payload.blog);
      }
    )
    .addCase(saveBlogBookmark.rejected, (state: UserStateType, action: any) => {})
    .addCase(saveBlogBookmark.pending, (state: UserStateType) => {});
  return builder;
};

export const {
  resetUserState,
  setUserEmail,
  setIsUserLoading,
  removeBookmark,
} = userSlice.actions;
export default userSlice.reducer;
