import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest, sendPostRequest } from './requestUtil';

export const getAllUserInfoByEmail = createAsyncThunk(
  'get-all-user-info-by-email',
  async (email: string) => {
    return sendGetRequest(`/api/get-all-user-info-by-email/${email}/`);
  }
);

export const getUserByUsername = createAsyncThunk(
  'get-user-by-username',
  async (username: string) => {
    return sendGetRequest(`/api/validate-username/${username}`);
  }
);

export const loginUser = createAsyncThunk(
  'login-user',
  async (body: LoginRequestType) => {
    return sendPostRequest('/api/auth/token/login/', body);
  }
);

export const signUpUser = createAsyncThunk(
  'sign-up-user',
  async (body: SignUpRequestType) => {
    return sendPostRequest('/api/auth/users/', body);
  }
);

export const uploadProfilePicture = createAsyncThunk(
  'upload-profile-picture',
  async (body: FormData, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/upload-profile-picture/', body, token);
  }
);

export const createUpdateProfile = createAsyncThunk(
  'create-profile',
  async (body: CreateProfileType, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/profile-create-update/', body, token);
  }
);
