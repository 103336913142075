import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest } from './requestUtil';

// ! for now this is for testing getting a list of blogs
export const getBlogTestList = createAsyncThunk('get-blogs', async () => {
  const result: ServerPaginatorType = await sendGetRequest(`/api/blogs/`); //BlogListSuccessType
  return result;
});

export const getBlogByProfileTags = createAsyncThunk(
  'get-profile-tag-blogs',
  async ({ page, seed }: { page?: string; seed?: number }, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    let params = [];
    if (page) params.push(`page=${page}`);
    if (seed) params.push(`seed=${seed}`);
    let query = params.join('&');
    const result: any = await sendGetRequest(
      `/api/blogs-by-profile-tags/?${query}`,
      token
    );
    return result;
  }
);

export const getBlogRecentList = createAsyncThunk(
  'get-recent-blogs',
  async (page?: string) => {
    let queryParams = '';
    if (page) queryParams += `page=${page}`;
    const result: ServerPaginatorType = await sendGetRequest(
      `/api/blogs/recent/?${queryParams}`
    );
    return result;
  }
);

export const getBlogShuffledList = createAsyncThunk(
  'get-shuffled-blogs',
  async ({ page, seed }: { page?: string; seed?: number }) => {
    let params = [];
    if (page) params.push(`page=${page}`);
    if (seed) params.push(`seed=${seed}`);
    let query = params.join('&');
    const result: ServerPaginatorType = await sendGetRequest(
      `/api/blogs/shuffled/?${query}`
    );
    return result;
  }
);
