export const convertStringArrayToTagOptionsArray = (stringArray: string[]) => {
  return stringArray.map(string => {
    return { value: string, label: string };
  });
};

export const convertTagOptionsArrayToStringArray = (
  tagOptionsArray: TagOptionType[]
) => {
  return tagOptionsArray.map(tagOption => tagOption.value);
};
