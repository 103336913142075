import { createSlice } from '@reduxjs/toolkit';

import { getBlogRecentList } from '../../actions/blogListActions';
import { blogListDefaultCases, initBlogListType } from './blogListReducerUtils';

const initialState = initBlogListType() as BlogListStateType;

// * create slice state
const blogRecentListSlice = createSlice({
  name: 'blogRecentListSlice',
  initialState,
  reducers: {
    resetBlogListState: () => initialState,
  },
  extraReducers: builder => {
    blogListDefaultCases(builder, getBlogRecentList);
  },
});

export const { resetBlogListState } = blogRecentListSlice.actions;
export default blogRecentListSlice.reducer;
