import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { searchBlogs } from '../actions/searchActions';
import { destructurePyPaginator } from './blogLists/blogListReducerUtils';

const initialState: SearchResultsType = {
  paginator: {
    count: 0,
    nextPageUrl: null,
    prevPageUrl: null,
    pageSize: 0,
  },
  blogs: [],
  isSearchLoading: false,
  searchErrorMessage: '',
  searchQuery: '',
};

const searchSlice = createSlice({
  name: 'profileStateSlice',
  initialState,
  reducers: {
    setSearchQuery: (
      state: SearchResultsType,
      action: PayloadAction<string>
    ) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: builder => {
    searchBlogsCases(builder);
  },
});

const searchBlogsCases = (
  builder: ActionReducerMapBuilder<SearchResultsType>
) => {
  builder.addCase(searchBlogs.pending, (state: SearchResultsType) => {
    state.isSearchLoading = true;
  });
  builder.addCase(searchBlogs.fulfilled, (state, action) => {
    destructurePyPaginator(state, action);
    state.isSearchLoading = false;
  });
  builder.addCase(searchBlogs.rejected, (state, action) => {
    state.searchErrorMessage =
      action.error.message || 'Failed to fetch results';
    state.isSearchLoading = false;
  });

  return builder;
};

export const { setSearchQuery } = searchSlice.actions;

export default searchSlice.reducer;
