const SideDrawerButtonComponent = (props: any) => {
  const { buttonText, iconName, isSelected, handleSideDrawerButtonClick } =
    props;

  const sideDrawerClass = isSelected
    ? 'side-drawer-button selected'
    : 'side-drawer-button';

  return (
    <button className={sideDrawerClass} onClick={handleSideDrawerButtonClick}>
      <div className={'nav-icon ' + iconName} />
      <div className="side-drawer-button-text">{buttonText}</div>
    </button>
  );
};

export default SideDrawerButtonComponent;
