import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';
import { getAllSubjectTags } from '../actions/profileActions';

const initialState = {
  availableTags: [],
} satisfies TagStateType as TagStateType;

const tagSlice = createSlice({
  name: 'profileStateSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getSubjectTagsCases(builder);
  },
});

const getSubjectTagsCases = (
  builder: ActionReducerMapBuilder<TagStateType>
) => {
  builder.addCase(getAllSubjectTags.pending, (state: TagStateType) => {
    // state.isUserLoading = true;
  });
  builder.addCase(
    getAllSubjectTags.fulfilled,
    (state: TagStateType, action: PayloadAction<any>) => {
      const tagOptions = action.payload?.tags?.map((interest: string) => ({
        value: interest,
        label: interest,
      }));
      state.availableTags = tagOptions;
    }
  );
  builder.addCase(
    getAllSubjectTags.rejected,
    (state: TagStateType, action: any) => {}
  );

  return builder;
};

export default tagSlice.reducer;
