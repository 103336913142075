import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest } from './requestUtil';

export const searchBlogs = createAsyncThunk(
  'search-blogs',
  async (params: any) => {
    const { query, page } = params;
    let queryParams = '';
    if (page) queryParams += `page=${page}&`;
    queryParams += `query=${query}`;
    return await sendGetRequest(`/api/search/?${queryParams}`);
  }
);
