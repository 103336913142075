import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import {
  getBlogDetailsById,
  getBlogDiscussionById,
  getBlogReviewsById,
  uploadBlogImage,
} from '../actions/blogPageActions';

// * initial state
const initialState: BlogPageStateType = {
  blogDetails: {},
  blogDiscussionsList: {},
  blogReviewsList: {},
  isBlogPageLoading: false,
  blogPageErrorMessage: '',
  isBlogUploading: false,
} as any;

// * create slice state
const blogPageSlice = createSlice({
  name: 'blogDetailStateSlice',
  initialState,
  reducers: {
    setCurrentBlogDetail: (
      state: BlogPageStateType,
      action: PayloadAction<BlogCard>
    ) => {
      state.blogDetails = action.payload;
    },
    resetBlogDetailState: () => initialState,
  },
  extraReducers: builder => {
    blogDetails(builder);
    blogReviews(builder);
    blogDiscussions(builder);
    uploadBlogImageCases(builder);
  },
});

const blogDetails = (builder: ActionReducerMapBuilder<BlogPageStateType>) => {
  builder.addCase(getBlogDetailsById.pending, (state: BlogPageStateType) => {
    state.blogDetails = {
      id: -1,
      url: '',
      title: '',
      tags: [],
      description: '',
      image_blog_page: '',
      isLoading: true,
    };
  });

  builder.addCase(
    getBlogDetailsById.fulfilled,
    (state: BlogPageStateType, action: PayloadAction<BlogCard>) => {
      state.blogDetails = action.payload;
      state.blogDetails.isLoading = false;
    }
  );

  builder.addCase(
    getBlogDetailsById.rejected,
    (state: BlogPageStateType, action: any) => {
      state.blogDetails = {
        id: -1,
        url: '',
        title: '',
        tags: [],
        description: '',
        image_blog_page: '',
        isLoading: true,
      };
      state.blogDetailErrorMessage = action.payload.detail;
    }
  );
};

const blogReviews = (builder: ActionReducerMapBuilder<BlogPageStateType>) => {
  builder.addCase(getBlogReviewsById.pending, (state: BlogPageStateType) => {
    state.isBlogPageLoading = true;
  });

  builder.addCase(
    getBlogReviewsById.fulfilled,
    (
      state: BlogPageStateType,
      action: PayloadAction<BlogReviewsListSuccessType>
    ) => {
      state.isBlogPageLoading = false;
      state.blogReviewsList = action.payload;
    }
  );

  builder.addCase(
    getBlogReviewsById.rejected,
    (state: BlogPageStateType, action: any) => {
      state.isBlogPageLoading = false;
      state.blogDetailErrorMessage = action.payload.detail;
    }
  );
};

const blogDiscussions = (
  builder: ActionReducerMapBuilder<BlogPageStateType>
) => {
  builder.addCase(getBlogDiscussionById.pending, (state: BlogPageStateType) => {
    state.isBlogPageLoading = true;
  });

  builder.addCase(
    getBlogDiscussionById.fulfilled,
    (
      state: BlogPageStateType,
      action: PayloadAction<BlogDiscussionsListSuccessType>
    ) => {
      state.isBlogPageLoading = false;
      state.blogDiscussionsList = action.payload;
    }
  );

  builder.addCase(
    getBlogDiscussionById.rejected,
    (state: BlogPageStateType, action: any) => {
      state.isBlogPageLoading = false;
      state.blogDetailErrorMessage = action.payload.detail;
    }
  );
};

const uploadBlogImageCases = (
  builder: ActionReducerMapBuilder<BlogPageStateType>
) => {
  builder.addCase(uploadBlogImage.pending, (state: BlogPageStateType) => {
    state.isBlogUploading = true;
  });
  builder.addCase(
    uploadBlogImage.fulfilled,
    (state: BlogPageStateType, action: PayloadAction<any>) => {
      state.isBlogUploading = false;
      state.blogDetails.image_blog_page = action.payload.blogImageUrl;
    }
  );
  builder.addCase(
    uploadBlogImage.rejected,
    (state: BlogPageStateType, action: any) => {
      state.isBlogUploading = false;
    }
  );

  return builder;
};

export const { setCurrentBlogDetail, resetBlogDetailState } =
  blogPageSlice.actions;
export default blogPageSlice.reducer;
