const Backdrop = (props: any) => {
  const { show, handleClick, customClass } = props;

  const backdropClasses = show ? 'backdrop visible ' : 'backdrop ';

  return (
    <div className={backdropClasses + customClass} onClick={handleClick}></div>
  );
};

export default Backdrop;
